/*------------- #COUNTERS --------------*/

.counters {
  overflow: hidden;
  padding: 80px 0;

  @media (max-width: 1024px) {
    text-align: center;
  }

  @media (max-width: 800px) {
    padding: 35px 0;
  }

  .counter-item {

    @media (max-width: 1024px) {
      margin-bottom: 30px;
    }

    .counter-numbers {
      font-size: 100px;
      color: rgba(255, 255, 255, .2);
      line-height: 1;
      margin-bottom: -36px;

      @media (max-width: 640px) {
        font-size: 50px;
      }

      @media (max-width: 570px) {
        margin-bottom: 0;
      }

      .units {
        display: inline-block;
        margin-left: -25px;

        @media (max-width: 640px) {
          margin-left: -15px;
        }
      }
    }

    .counter-title {
      font-size: 24px;
      color: $white-color;
      text-transform: uppercase;

      @media (max-width: 640px) {
        font-size: 18px;
      }
    }

    .counter-line {
      .short-line {
        display: inline-block;
        width: 20px;
        height: 3px;
        background-color: #66c491;
        border-radius: 10px;
        position: relative;
        margin-right: 10px;
      }

      .long-line {
        display: inline-block;
        width: 50px;
        height: 3px;
        background-color: #66c491;
        border-radius: 10px;
        position: relative;
      }
    }

  }
}

/*------------- #Counter modern --------------*/

.counters-modern {
  overflow: hidden;
  position: relative;
  z-index: 2;

  .counter-item-modern {
    padding-right: 35px;

    @media (max-width: 1024px) {
      margin-bottom: 30px;
    }

    @media (max-width: 640px) {
      padding-right: 0;
    }

    i {
      margin-bottom: 20px;
      color: #64d8ff;
      font-size: 70px;
      display: block;

      &.c-primary {
        color: $primary-color;
      }

      &.c-secondary {
        color: $secondary-color;
      }

      &.c-green {
        color: #3cb878 !important;
      }

      &.c-orange {
        color: #fcb03b !important;
      }
    }

   .counter-numbers-modern {
      font-size: 100px;
      line-height: 1;

     @media (max-width: 1024px) {
       font-size: 80px;
     }

     @media (max-width: 640px) {
       font-size: 50px;
     }

     .units {
       display: inline-block;
       margin-left: -25px;

       @media (max-width: 640px) {
         margin-left: -10px;
       }
     }

     &.negative-margin {
       margin-bottom: -36px;

       @media (max-width: 640px) {
         margin-bottom: 0;
       }
     }
   }

    .counter-title-modern {
      color: $white-color;
      font-size: 24px;
      line-height: 1.3;
    }

    .counter-line-modern {
      .short-line-modern {
        display: inline-block;
        width: 20px;
        height: 3px;
        border-radius: 10px;
        position: relative;
        margin-right: 10px;
      }

      .long-line-modern {
        display: inline-block;
        width: 50px;
        height: 3px;
        border-radius: 10px;
        position: relative;
      }
    }
  }
}
