/*------------- #BOOKS --------------*/


.books-grid {
  .books-grid-control {
    text-align: center;
    margin-bottom: 60px;

    .showing {
      margin-right: 60px;
      display: inline-block;
      font-size: 16px;
      color: $font-color;

      @media (max-width: 480px) {
        margin-right: 30px;
      }

      span {
        color: $heading-color;
      }
    }

    .sort {
      display: inline-block;
      font-size: 16px;
      position: relative;

     .nice-select {
       min-width: 300px;
     }

      .dropdown-sort-menu {
        padding: 15px;
        text-align: left;
        background-color: $primary-color;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 100%;
        right: 0;
        transition: all .3s ease;
        z-index: 10;

        .dropdown-sort-submenu {
          padding: 10px;

          a {
            color: $white-color;
            transition: all .3s ease;
            width: 100%;
            display: block;
          }

          &:hover {
            a {
              color: $heading-color;
            }
          }

        }
      }
    }
  }
}


.books-item {
  text-align: center;
  background-color: $border-color;
  padding: 60px;

  @media (max-width: 1024px) {
    padding: 30px;
  }

  @media (max-width: 768px) {
    margin: 0 15px 30px;
  }

  .books-item-thumb {
    position: relative;
    max-width: 170px;
    margin: 0 auto 40px;

    .overlay-books {
      background-color: rgba(255, 255, 255, .7);
      opacity: 0;
    }

    .new {
      width: 55px;
      height: 55px;
      line-height: 55px;
      text-align: center;
      position: absolute;
      top: -15px;
      left: -15px;
      background-color: #8dc63f;
      font-size: 14px;
      color: $white-color;
      border-radius: 100%;
      display: none;
    }

    .sale {
      width: 55px;
      height: 55px;
      line-height: 55px;
      text-align: center;
      position: absolute;
      top: -15px;
      left: -15px;
      background-color: #f15b26;
      font-size: 14px;
      color: $white-color;
      border-radius: 100%;
      display: none;
    }
  }

  .books-item-info {
    margin-bottom: 30px;

    .books-author {
      font-size: 14px;
      color: $font-color;
      margin-bottom: 20px;
    }

    .books-title {
      margin-bottom: 20px;
      display: block;

      &:hover {
        color: $primary-color;
      }
    }

    .books-price {
      font-size: 16px;
      color: #f26522;

      .del {
        font-size: 14px;
        color: $font-color;
        text-decoration: line-through;
        margin-right: 5px;
      }

      &.free {
        color: #8dc63f;
      }
    }

  }

  &.new {
    .new{
      display: block;
    }
  }

  &.sale {
   .sale {
      display: block;
    }
  }

  &.added {
    .overlay-books {
      opacity: 1;
    }

    .books-item-info {

      .books-title {
        color: $primary-color;
      }

    }

  }

}

