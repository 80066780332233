/*------------- #CART --------------*/


.cart-main {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 15px 0 65px rgba(0, 0, 0, .3);
  margin-bottom: 80px;

  table {
    margin-bottom: 0;
  }

  tbody {
    background-color: $white-color;

    .cart_item {
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 95%;
        height: 2px;
        background-color: $border-color;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
      }

    }

  }

    .cart-product-wrap-title-main {

      tr > th {
        color: $white-color;
        font-size: 16px;
        text-transform: uppercase;
        background-color: $heading-color;
        padding: 30px 0;
        text-align: left;

        @media (max-width: 460px) {
          font-size: 12px;
        }
      }
    }

  .cart_item {

    td {
      padding: 40px 0;
    }

    .product-remove {
      .product-del {
        position: relative;
        font-size: 20px;
        cursor: pointer;
        padding: 0 55px;
        color: #ed1c24;
        display: block;

        @media (max-width: 768px) {
          padding: 0 15px;
        }

        @media (max-width: 460px) {
          padding: 0 7px;
          font-size: 9px;
        }
      }
    }

    .product-thumbnail {

      .cart-product__item {
        display: flex;
        align-items: center;

        @media (max-width: 360px) {
          display: block;
        }

        img {
          float: left;
          margin-right: 40px;
          display: table-cell;
          vertical-align: middle;

          @media (max-width: 460px) {
            margin-right: 0;
          }
        }

        .cart-product-content {
          text-align: left;

          .cart-author {
            margin-bottom: 5px;
          }

          .cart-product-title {
            line-height: 1;
          }

        }

      }

    }

    .product-quantity {
      .quantity {
        overflow: hidden;

        .quantity-minus {
          display: block;
          float: left;
          padding: 0 20px;
          background-color: #f1f3f3;
          border-radius: 50px 0 0 50px;
          font-size: 40px;
          border-right: 2px solid #e3e9e9;
          color: #cfcfcf;
          transition: all .3s ease;

          @media (max-width: 980px) {
            font-size: 20px;
            padding: 0 10px;
          }

          @media (max-width: 480px) {
            font-size: 14px;
            padding: 0 5px;
          }

          &:hover {
            background-color: $primary-color;
            color: $white-color;
            border-color: $primary-color;
          }
        }

        input {
          max-width: 70px;
          float: left;
          background-color: $border-color;
          padding: 18px 20px;
          font-size: 16px;
          text-align: center;
          border-radius: 0;
          margin-bottom: 0;

          @media (max-width: 980px) {
            font-size: 18px;
            padding: 0 10px;
            max-width: 40px;
          }

          @media (max-width: 480px) {
            font-size: 14px;
            padding: 0 5px;
            max-width: 30px;
          }
        }

        .quantity-plus {
          display: block;
          float: left;
          padding: 0 20px;
          background-color: #f1f3f3;
          border-radius: 0 50px 50px 0;
          font-size: 40px;
          border-left: 2px solid #e3e9e9;
          color: #cfcfcf;
          transition: all .3s ease;

          @media (max-width: 980px) {
            font-size: 20px;
            padding: 0 10px;
          }

          @media (max-width: 480px) {
            font-size: 14px;
            padding: 0 5px;
          }

          &:hover {
            background-color: $primary-color;
            color: $white-color;
            border-color: $primary-color;
          }
        }
      }

    }

    .product-subtotal {
      padding-right: 45px;

      @media (max-width: 570px) {
        padding-right: 10px;
      }

      .total {
        color: #f26522;
      }
    }

  }

  .actions {
    padding: 40px 70px;
    background-color: #f1f3f3;
    overflow: hidden;

    @media (max-width: 570px) {
      padding: 30px;
    }

    .coupon {
      float: left;
      overflow: hidden;

      @media (max-width: 980px) {
        margin-bottom: 30px;
      }

        input {
          width: auto;
          border-radius: 50px 0 0 50px;
          background-color: $white-color;
          padding: 19px 40px;
          float: left;
          margin-bottom: 0;

          @media (max-width: 600px) {
            border-radius: 50px;
            margin-bottom: 30px;
          }
        }

      .btn-medium {
        border-radius: 0 50px 50px 0;
        float: left;

        @media (max-width: 768px) {
          padding: 23px 31px;
        }

        @media (max-width: 600px) {
          border-radius: 50px;
        }
      }
    }

    .btn {
      float: right;

      @media (max-width: 980px) {
        float: left;
      }
    }
  }

}

.cart-total {
  text-align: center;

  .cart-total-title {
    font-size: 40px;
    margin-bottom: 30px;
  }

  .cart-total-subtotal {
    margin-bottom: 5px;
  }

  .cart-total-total {
    margin-bottom: 40px;

    .price {
      color: #f26522;
    }
  }

  .btn-medium {
    padding-left: 105px;
    padding-right: 105px;
  }
}

.cart-title {
  text-align: center;
  margin-bottom: 60px;
}
