/*------------- #SKILLS --------------*/


.skills {

  .skills-item {
    margin-bottom: 25px;

    .skills-item-info {
      margin-bottom: 15px;

      .skills-item-title {
        font-size: 16px;
        color: #797676;
      }

      .skills-item-count {
        font-size: 16px;
        color: $white-color;
        float: right;
      }

    }

    .skills-item-meter {
      padding: 0 5px;
      width: 100%;
      border-radius: 10px;
      background-color: #373434;
      position: relative;
      height: 17px;

      .skills-item-meter-active {
        position: relative;
        height: 6px;
        display: inline-block;
        border-radius: 5px;
        bottom: 6px;
        opacity: 0;
        transition: all .2s ease;

        &.skills-animate {
          animation: skills-animated 1.2s ease-out;
        }

        &.skills-animate-opacity {
          animation: skills-animated-opacity .2s ease-out;
        }

        &:after {
          content: '';
          display: block;
          width: 25px;
          height: 25px;
          border: 4px solid;
          border-color: inherit;
          border-radius: 100%;
          position: absolute;
          right: -23px;
          top: 50%;
          transform: translate(0, -50%);
          -webkit-transform: translate(0, -50%);
          -ms-transform: translate(0, -50%);
        }
      }

      &.bg-border-color {
        background-color: $border-color;
      }
    }

  }
}

@keyframes skills-animated {
  0% {width: 0;}
}

@keyframes skills-animated-opacity {
  100% {opacity: 1;}
}