/*------------- #PRODUCT-DETAILS --------------*/

.product-details {

  @media (max-width: 570px) {
	overflow: hidden;
  }

  .product-details-thumb {
	padding: 80px 100px;
	position: relative;
	background-color: $border-color;
	box-shadow: 0 25px 40px rgba(0, 0, 0, .3);
	text-align: center;
	margin-bottom: 30px;

	@media (max-width: 980px) {
	  padding: 30px;
	}

	.swiper-wrapper {
	  margin-bottom: 50px;
	}

	.sale {
	  width: 80px;
	  height: 80px;
	  line-height: 80px;
	  text-align: center;
	  position: absolute;
	  top: 0;
	  right: 0;
	  background-color: #f15b26;
	  font-size: 18px;
	  color: $white-color;
	  border-radius: 100%;
	}

	.pagination, .swiper-pagination {
	  bottom: 0;
	  left: 50%;
	  transform: translate(-50%, 0);

	  .swiper-pagination-bullet {
		background-color: #d7d7d7;

		&.swiper-pagination-bullet-active {
		  background-color: transparent;
		  border: 2px solid $primary-color;
		}
	  }
	}
  }

  .product-details-info {
	padding-bottom: 40px;
	margin-bottom: 40px;
	border-bottom: 2px solid $border-color;
	position: relative;
	z-index: 10;

	@media (max-width: 570px) {
	  padding-top: 40px;
	}

	.product-details-info-price {
	  color: #f26522;
	  font-size: 24px;
	  margin-bottom: 15px;

	  .del {
		color: #cccccc;
		font-size: 18px;
		margin-right: 15px;
		text-decoration: line-through;
	  }
	}

	.product-details-info-title {
	  margin-bottom: 20px;
	}

	.product-details-info-ratings {
	  margin-bottom: 35px;

	  .ratings {
		display: inline-block;
		margin-right: 20px;
		color: #cccccc;

		.fa-star {
		  color: #fcd846;
		}

	  }

	  .reviews {
		display: inline-block;
		font-size: 16px;
		color: $primary-color;
	  }
	}

	.product-details-info-text {
	  margin-bottom: 40px;
	}

	.quantity {
	  overflow: hidden;
	  margin-right: 30px;
	  float: left;

	  @media (max-width: 980px) {
		margin-bottom: 30px;
	  }

	  .quantity-minus {
		display: block;
		float: left;
		padding: 0 20px;
		background-color: #f1f3f3;
		border-radius: 50px 0 0 50px;
		font-size: 40px;
		border-right: 2px solid #e3e9e9;
		color: #cfcfcf;
		transition: all .3s ease;
		height: 65px;
		line-height: 65px;

		&:hover {
		  color: $white-color;
		  background-color: $heading-color;
		  border-right-color: $heading-color;
		}
	  }

	  input {
		max-width: 70px;
		float: left;
		background-color: #f7f9f9;
		padding: 18px 20px;
		font-size: 16px;
		text-align: center;
		border-radius: 0;
		height: 65px;
	  }

	  .quantity-plus {
		display: block;
		float: left;
		padding: 0 20px;
		background-color: #f1f3f3;
		border-radius: 0 50px 50px 0;
		font-size: 40px;
		border-left: 2px solid #e3e9e9;
		color: #cfcfcf;
		transition: all .3s ease;
		height: 65px;
		line-height: 65px;

		&:hover {
		  color: $white-color;
		  background-color: $heading-color;
		  border-right-color: $heading-color;
		}
	  }
	}

	.btn i {
	  font-size: 18px;
	}
  }

  .product-details-add-info {
	.author {
	  font-size: 16px;
	  color: $heading-color;
	  margin-bottom: 15px;

	  .author-name {
		color: $primary-color;
	  }
	}

	.tags {
	  font-size: 16px;
	  color: $heading-color;
	  margin-bottom: 15px;

	  .tags-item {
		color: $font-color;

		&:hover {
		  color: $primary-color;
		}
	  }
	}

	.socials {
	  font-size: 16px;
	  color: $heading-color;
	  margin-bottom: 15px;

	  .social__item {
		i {
		  font-size: 20px;
		  color: #d7d7d7;
		  transition: all .3s ease;
		}

		&:hover {
		  i {
			color: #08acee;
		  }
		}
	  }

	  .social__item:first-child {
		margin-left: 15px;
	  }
	}
  }
}

.product-description {
  background-color: $border-color;
  position: relative;
  padding-bottom: 120px;

  &:before {
	content: '';
	display: block;
	width: 100%;
	height: 77px;
	background-color: #fff;
	position: relative;
  }

  .tab-content > .tab-pane {
	display: none;

	&.active {
	  display: block;
	}
  }

  .product-description-control {
	margin-top: -77px;
	margin-bottom: 50px;
	float: left;
	padding: 0;

	li {
	  display: inline-block;

	  .control-item {
		padding: 25px 70px;
		text-align: center;
		text-transform: uppercase;
		background-color: #f1f3f3;
		border-right: 2px solid #e3e9e9;
		border-radius: 50px 0 0 0;
		position: relative;
		font-size: 18px;
		float: left;

		@media (max-width: 480px) {
		  padding: 25px 30px;
		  font-size: 16px;
		}

		@media (max-width: 320px) {
		  padding: 15px;
		  font-size: 14px;
		}

		&:before {
		  content: '';
		  display: block;
		  width: 60%;
		  height: 2px;
		  background-color: $primary-color;
		  position: absolute;
		  top: 0;
		  left: 50%;
		  transform: translate(-50%, 0);
		  -webkit-transform: translate(-50%, 0);
		  -ms-transform: translate(-50%, 0);
		  opacity: 0;
		}

		&.reviews {
		  border-right: none;
		  border-radius: 0 50px 0 0;
		}

	  }

	  &.active {

		.control-item {
		  color: $primary-color;
		  background-color: $border-color;

		  &:before {
			opacity: 1;
		  }
		}

	  }

	  &:last-child {
		margin-left: -4px;
	  }

	}

	@media (max-width: 480px) {
	  margin-top: -74px;
	}

	@media (max-width: 320px) {
	  margin-top: -51px;
	  margin-bottom: 30px;
	}

  }

  .product-description-title {
	font-size: 40px;
	color: $heading-color;
	margin-bottom: 40px;

	@media (max-width: 1024px) {
	  font-size: 26px;
	}
  }

  .comments__list-review {
	.comments__item-review {
	  .comment-content p {
		margin-bottom: 30px;
	  }
	}
  }

  .add-review {
	.your-rating {
	  padding-left: 40px;
	  font-size: 16px;

	  .ratings {
		display: inline-block;
		margin-left: 50px;
		color: #ccc;
		margin-bottom: 30px;

		.fa-star {
		  color: #fcd846;
		}
	  }
	}

	.contact-form {
	  padding-bottom: 20px;

	  input, textarea {
		background-color: $white-color;
	  }

	  textarea {
		min-height: 230px;
	  }
	}
  }

  .product-description-text {
	margin-bottom: 30px;
  }
}

.product-description-ver2 {
  .product-description-ver2-thumb {
	position: relative;
	box-shadow: 2px 6px 35px -7px rgba(0, 0, 0, 0.3);
	margin: 35px;

	@media (max-width: 768px) {
	  margin-bottom: 100px;
	}
  }

  .pagination, .swiper-pagination {
	bottom: auto;
	left: auto;
	transform: none;
	margin-top: 30px;
	position: relative;
  }

  .product-description-ver2-content {

	@media (max-width: 768px) {
	  padding-top: 90px;
	}

	.post__date {
	  background: $white-color;
	  float: left;
	}

	.heading {
	  clear: both;
	  margin-bottom: 50px;
	}

	.likes-block {
	  .btn {
		display: inline-block;
		margin-right: 30px;
	  }

	  a {
		display: inline-block;
		color: #ff454f;
		margin-bottom: -10px;

		.count-likes {
		  font-size: 18px;
		  float: left;
		}

		i {
		  font-size: 26px;
		  float: left;
		  margin-right: 5px;
		}
	  }
	}
  }

  .swiper-container {

	.swiper-slide {
	  opacity: 0;

	  &.swiper-slide-active {
		opacity: 1;
	  }
	}
  }
}

.likes-block {
  .btn {
	display: inline-block;
	margin-right: 30px;
  }

  a {
	display: inline-block;
	color: #ff454f;
	margin-bottom: -10px;

	.count-likes {
	  font-size: 18px;
	  float: left;
	}

	i {
	  font-size: 26px;
	  float: left;
	  margin-right: 5px;
	}
  }
}

.likes-block {
  .btn {
	display: inline-block;
	margin-right: 30px;
	margin-bottom: 30px;
  }

  a {
	display: inline-block;

	.count-likes {
	  color: #ff454f;
	  font-size: 18px;
	}
  }
}

.challenge-solutions {
  .challenge-solutions-thumb {
	border: 2px solid $border-color;
	border-radius: 10px;
	padding: 30px;

	img {
	  margin-bottom: 30px;
	}
	.text {
	  padding-left: 30px;
	  font-size: 14px;
	  margin-bottom: 0;
	}
  }

  .challenge-solutions-content {
	.heading {
	  &:first-child {
		margin-bottom: 60px;
	  }
	}
  }
}

.seo-results {
  .seo-results-thumb {

	canvas {
	  margin-bottom: 60px;
	}

	.points {
	  padding-left: 50px;

	  @media (max-width: 1024px) {
		padding-left: 0;
	  }

	  .points-item {
		align-items: center;
		margin-bottom: 20px;

		.point-sircle {
		  display: inline-block;
		  width: 20px;
		  height: 20px;
		  border-radius: 100%;
		  border-color: transparent;
		  margin-right: 20px;
		  float: left;
		}

		.points-item-count {
		  font-size: 14px;
		  color: $heading-color;
		  display: inline-block;
		}

	  }
	}
  }

  .seo-results-content {
	.heading {
	  margin-bottom: 80px;
	}

	.results {
	  padding-left: 100px;

	  @media (max-width: 980px) {
		padding-left: 0;
	  }

	  .results-item {
		display: flex;
		align-items: center;
		margin-bottom: 40px;

		i {
		  display: inline-block;
		  margin-right: 30px;
		  font-size: 70px;
		  color: $primary-color;
		}

		.results-item-info {
		  display: inline-block;
		  font-size: 30px;
		  color: $heading-color;

		  @media (max-width: 980px) {
			font-size: 24px;
		  }
		}
	  }
	}
  }
}

.product-description-ver3 {
  position: relative;

  .product-description-ver3-thumb {
	position: absolute;
	top: 60px;
  }
}

.product-description-challenge {
  position: relative;
  margin-top: 60px;

  @media (max-width: 640px) {
	padding: 0 15px;
  }

  .product-description-thumb {
	z-index: 5;
	margin-top: -60px;
	margin-left: -30px;
	position: relative;

	@media (max-width: 640px) {
	  margin-left: 0;
	}
  }

  .product-description-content {
	padding: 95px 0 40px;

	.heading {

	  .heading-title {
		line-height: 1;
	  }

	  p {
		line-height: 1.4;
	  }
	}
  }

  .product-description-border {
	border: 6px solid $border-color;
	position: absolute;
	height: 100%;
	width: 80%;
	top: 0;
	right: 0;

	@media (max-width: 640px) {
	  width: 100%;
	}
  }
}

.product-description-solution {
  position: relative;
  margin-top: 60px;

  @media (max-width: 640px) {
	padding-left: 20px;
	margin-top: 0;
  }

  .product-description-thumb {
	z-index: 5;
	margin-top: -60px;
	margin-left: 60px;
	position: relative;

	img {
	  @media (max-width: 640px) {
		margin-bottom: -30px;
	  }
	}

	@media (max-width: 640px) {
	  margin-top: 0;
	  margin-left: 0;
	}
  }

  .product-description-content {
	padding: 95px 0 40px;

	@media (max-width: 640px) {
	  padding-top: 25px;
	}

	.heading {

	  .heading-title {
		line-height: 1;
	  }

	  p {
		line-height: 1.4;
		margin-bottom: 30px;

		&:last-child {
		  margin-bottom: auto;
		}
	  }
	}
  }

  .product-description-border {
	border: 6px solid $border-color;
	position: absolute;
	height: 100%;
	width: 80%;
	top: 0;
	left: 0;

	@media (max-width: 640px) {
	  width: 100%;
	}
  }
}

.seo-results-ver2 {
  .seo-results-content {
	.list {
	  padding-left: 60px;

	  @media (max-width: 1024px) {
		padding-left: 0;
	  }
	}
  }

  .seo-results-thumb {
	border-radius: 10px;
	border-color: transparent;
	box-shadow: 24px 50px 60px rgba(0, 0, 0, 0.3);
  }

  .results {
	padding-left: 100px;

	@media (max-width: 1024px) {
	  padding-left: 0;
	}

	.results-item {
	  display: flex;
	  align-items: center;
	  margin-bottom: 40px;
	  width: 33.33%;
	  float: left;

	  @media (max-width: 980px) {
		width: 100%;
	  }

	  @media (max-width: 768px) {
		margin-bottom: 15px;
	  }

	  i {
		display: inline-block;
		margin-right: 30px;
		font-size: 70px;
		color: $primary-color;
	  }

	  .results-item-info {
		display: inline-block;
		font-size: 30px;
		color: $heading-color;

		@media (max-width: 768px) {
		  font-size: 24px;
		}
	  }
	}
  }
}