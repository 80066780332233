/*------------- #TEAMMEMBERS --------------*/


.teammembers-item {
  text-align: center;

  img {
    margin-bottom: 30px;
  }

  .teammembers-item-name {
    margin-bottom: 5px;
  }

  .teammembers-item-prof {
    margin-bottom: 15px;
  }

  .socials .social__item {
    margin-left: 10px;
  }
}