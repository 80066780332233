/*------------- #PRICING-TABLES --------------*/


.pricing-tables {

  .pricing-tables-wrap {
    background-color: $white-color;
    overflow: hidden;
    box-shadow: 0 14px 32px 10px rgba(0, 0, 0, .2);
    margin: 0 15px;
    border-radius: 10px;

    :nth-child(3) .pricing-tables-item .dots {
      display: none;
    }

  }
}

.pricing-tables-item {
  text-align: center;
  padding: 60px;
  position: relative;

  @media (max-width: 1024px) {
    padding: 15px;
  }

  @media (max-width: 570px) {
    margin-bottom: 30px;
  }

  &:hover {
    .btn {
      background-color: $primary-color;
    }

    .pricing-title {
      color: $primary-color;
    }
  }

  .pricing-tables-icon {
    width: 130px;
    height: 130px;
    border: 15px solid $border-color;
    border-radius: 50%;
    position: relative;
    margin: 0 auto 15px;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }

  .pricing-title {
    font-size: 34px;
    margin-bottom: 11px;
    display: block;

    @media (max-width: 980px) {
      font-size: 26px;
    }
  }

  .pricing-tables-position {
    font-size: 16px;
    margin: 0 0 21px 0;
    padding: 0;
    list-style: none;

    .position-item {
      margin-bottom: 5px;

      &.include {
        color: #fcb03b;
      }

      .count {
        color: $heading-color;
        font-weight: 600;
      }
    }
  }

  .rate {
    font-size: 24px;
    color: $primary-color;
    margin-bottom: 31px;
  }

  .dots {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);

    @media (max-width: 570px) {
      display: none;
    }
  }

}

.pricing-tables-head {

  .pricing-tables-item {
    border-radius: 10px;
    padding: 0;
    overflow: hidden;
    box-shadow: 8px 0 87px rgba(0, 0, 0, 0.15);
    transition: all .3s ease;

    .pricing-head {
      position: relative;
      min-height: 125px;
    }

    .pricing-content-wrap {
      margin-top: -60px;
      background-color: $white-color;
      padding: 0 60px 60px;

      .pricing-tables-icon {
        background-color: $white-color;
      }
    }

    &:hover {
      transform: scale(1.1);
      z-index: 5;

      .btn {
       background-color: $secondary-color;
      }
    }
  }

}

.pricing-tables-classic {
  .pricing-tables-item {
    background-color: $white-color;
    box-shadow: 15px 0 80px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
}

.pricing-tables-colored {
  .pricing-tables-item {
    box-shadow: 15px 0 80px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    .pricing-tables-icon {
      border: none;
    }

    .pricing-title {
      &:hover {
        color: $heading-color;
      }
    }

    .pricing-tables-position {
      .position-item {
        color: $white-color;

        .count {
          color: $white-color;
        }
      }
    }

    .rate {
      color: $heading-color;
    }

    &:hover {
      .btn {
          border-color: transparent;
          background-color: $heading-color;
      }

      .pricing-title {
          color: $heading-color;

      }
    }

  }
}