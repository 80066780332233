.contacts {

  @media (max-width: 1024px) {
    display: block;
  }

  .contacts-item {
    @media (max-width: 1024px) {
      margin-bottom: 25px;
    }

    .icon {
      width: 70px;
      height: 70px;
      float: left;
      margin-right: 30px;
    }

    img {
      float: left;
      margin: auto 30px auto auto;
    }

    .content {
      margin-left: auto;
      overflow: hidden;

      .title {
        font-size: 24px;

        @media (max-width: 1024px) {
          font-size: 13px;
        }

        &:hover {
          color: $primary-color;
        }
      }

      .sub-title {
        font-size: 16px;

        @media (max-width: 1024px) {
          font-size: 13px;
        }
      }
    }
  }
}

.contacts-shadow {
  box-shadow: 40px 0 80px rgba(0, 0, 0, .15);
}

#map {
  width: 100%;
  height: 620px;
  max-height: 90vh;
}