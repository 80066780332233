/*------------- #FOOTER --------------*/


.footer {
  padding-top: 110px;
  background-color: #151414;
  color: rgb(255, 255, 255);

  @media (max-width: 768px) {
    padding-top: 40px;
  }

  .info {
    padding-bottom: 110px;
    border-bottom: 2px solid rgba(255,255,255, .1);
    overflow: hidden;

    @media (max-width: 1024px) {
      padding-bottom: 40px;
    }

    .heading {
      margin-bottom: 23px;

      .heading-title {
        color: $white-color;
      }

      .heading-text {
        font-size: 16px;
        line-height: 1.6;
        opacity: .4;
      }
    }

    .services {

      ul {
        width: 50%;
        float: left;
        line-height: 1.2;

        @media (max-width: 1024px) {
          width: 100%;
        }
      }

      .list--primary {

        & > li {

          i {
            margin-right: 15px;
            margin-top: 3px;
            color: $font-color;
          }

          a {
            font-size: 14px;
            opacity: 1;
          }

          &:hover  {
            i {
              color: $primary-color;
              margin-left: 10px;
            }

            a {
              color: $primary-color;
            }
          }
        }

      }
    }

    .socials {
      @media (max-width: 1024px) {
        margin-bottom: 30px;
      }

    }

  }

  .contacts {
    padding: 40px 0;
    display: flex;

    @media (max-width: 570px) {
      display: block;
    }

    a {
      font-size: 24px;
      color: $white-color;
    }

    .contacts-item .content .sub-title {
      opacity: .4;
    }
  }

  a {
    color: rgba(255, 255, 255, .35);
    transition: all .3s ease;

    &:hover {
      color: $primary-color;
    }
  }

  .sub-footer {
    background-color: #121111;
    padding: 60px 0;
    position: relative;

    @media (max-width: 768px) {
      padding: 30px 0;
    }

    span {
      font-size: 14px;
      margin-right: 30px;
      color: #504D4E;

      @media (max-width: 980px) {
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 10px;
        display: block;
      }

      &:last-of-type {
        padding-right: 70px;
      }

    }
  }

  &.js-fixed-footer {
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 1;
    -webkit-transition-property: all;
    -moz-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0, 1);
    -moz-transition-timing-function: cubic-bezier(0, 0, 0, 1);
    transition-timing-function: cubic-bezier(0, 0, 0, 1);

    @media (max-width: 768px) {
      position: relative;
    }
  }
}

.content-wrapper {
  position: relative;
  z-index: 2;
  background-color: #fff;
  -webkit-margin-bottom-collapse: separate;
}

.block-footer-height {
  position: relative;
  width: 100%;

  @media (max-width: 768px) {
    display: none;
  }
}