/*------------- #HEADING --------------*/



.heading {
  position: relative;
  z-index: 5;

  .heading-title {
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 20px;

    &.no-margin {
      margin: 0;
    }
  }

  .heading-line {
    margin-bottom: 20px;
    position: relative;

    .short-line {
      display: inline-block;
      width: 30px;
      height: 3px;
      background-color: $primary-color;
      border-radius: 10px;
      position: relative;
      margin-right: 10px;
      transition: all .4s linear;


      &.bg-yellow-color {
        background-color: #fcd846;
      }
    }

    .long-line {
      display: inline-block;
      width: 100px;
      height: 3px;
      background-color: $primary-color;
      border-radius: 10px;
      position: relative;
      transition: all .4s linear;


      &.bg-yellow-color {
        background-color: #fcd846;
      }
    }
  }

  .heading-subtitle {

    margin-bottom: 15px;
  }

  p {
    margin-bottom: 30px;
  }

  .heading-text {
    font-size: 24px;
    line-height: 30px;

    @media (max-width: 980px) {
      font-size: 20px;
    }

    @media (max-width: 570px) {
      font-size: 16px;
    }
  }

  .read-more {
    font-size: 18px;
    color: #ccc;
    padding: 5px 0 0 30px;
    border-left: 1px solid #f0f2f2;
    margin: 15px 0 0 30px;
    display: block;
    float: left;

    i {
      margin-left: 10px;
      font-size: 12px;
    }

    &:hover {
      color: $primary-color;
    }
  }

  &:hover {
    .heading-line {

      .short-line {
        width: 0;
        animation: move-short 1.5s infinite linear;

        @keyframes move-short {

          100% {
            width: 140px;
            margin-right: 0;
          }

        }
      }

      .long-line {
        width: 130px;
        animation: move 1.5s infinite linear;

        @keyframes move {

          100% {
            width: 0;
          }
        }
      }
    }
  }
}
